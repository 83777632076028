import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { border, color, layout, space } from "styled-system";
import Label from "../Label";
import Icon from "../Icon";
import Box from "../Box";
import useMediaUpload from "../../customHooks/useMediaUpload";
import { useTranslation } from "react-i18next";

const UploadFileBody = styled(Box)`
  box-sizing: border-box;
  background: #004c73;
  border: 1px solid #EFF0F7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 8px;
  color: #A0AEC0;
  align-items: center;
  display: flex;
  padding-left: 65px;
  position: relative;
  ${layout}
  ${border}
  ${color}
  ${space}
`;
const ChooseFile = styled(Box)`
  width: 29.4% !important;
  min-width: max-content;
  height: 35px !important;
  right: 10px;
  position: absolute;
  background: transparent;
  border: 1px solid #7E7E7E !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -2px;
  ${layout}
  ${border}
  ${color}
  ${space}
`;
const ChooseLabel = styled(Label)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  letter-spacing: 0.015em;
  color: #A0AEC0;
  cursor: pointer;
  @media (max-width: 1350px) {
    font-size: 12px;
  }
`;
const UploadFileItem = styled(Box)`
  position: absolute;
  left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
`;
const UploadFile = ({ id, onOptionClick, ...rest }) => {
  const [fileName, setFileName] = useState(null);
  const {t} = useTranslation()

  const selector = `.${id}-input-handle`;

  const inputHandle = () => {
    document.querySelector(selector).click();
  };

  useEffect(() => {
    if (rest?.fileName) {
      setFileName(rest?.fileName)
    }
  }, [rest])

  const clearHandle = () => {
    setFileName(null);
    onOptionClick(null, null);
  };

  const mediaType = "IMAGE";

  const { handleUpload, status } = useMediaUpload({
    mediaType,
    onSuccess: (res, fileUploaded) => {
      setFileName(fileUploaded?.name);
      onOptionClick(res?.["media-url"], fileUploaded?.name);
    },
  });

  return (
    <UploadFileBody {...rest}>
      <ChooseFile style={{background:(rest?.isDisabled)?'#EFF0F7':''}} {...rest} onClick={() => inputHandle()}>
        <ChooseLabel color="#fff" ml="5px" mr="5px">{status === "loading" ? t("uploading..") : fileName ? t("Afbeelding wijzigen") : t("Kies afbeelding")}</ChooseLabel>
      </ChooseFile>
      <Box
        className={`${id}-input-handle`}
        onClick={handleUpload}
        {...rest}
      />
      <UploadFileItem>
        {fileName ? (
          <Box display="flex">
            <ChooseLabel p="4px 6px 4px 6px" border="1px solid #EFF0F7" borderRadius="8px" width="180px">{fileName}</ChooseLabel>
            <Icon iconName="filledClose" iconSize="2tiny" onClick={() => clearHandle()}/>
          </Box>
        ) : (
          <ChooseLabel>{t("Geen bestand geselecteerd")}</ChooseLabel>
        )}
      </UploadFileItem>
    </UploadFileBody>
  );
};

UploadFile.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
};

export default UploadFile;
