import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { DatePicker, Button } from "antd";
import { useSelector } from "react-redux";
import Label from "../../../../../components/Label";
import Box from "../../../../../components/Box";
// import CurveLineChart from "../../../../../components/D3Charts/CurveLineChart";
import LineChart from "../../../../../components/D3Charts/LineChart";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../components/Icon";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";


const FinancialBoxShadow = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 20px;
`;

const RevenueCard = () => {
  const [details, setDetails] = useState({});
  const [pending_details, setPendingDetails] = useState({});
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false)
  const memberRevenueStats = useSelector(
    (state) => state.mosqueReducer.memberRevenueStats
  );
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  useEffect(() => {
    if (memberRevenueStats?.length > 0) {
      const result = {};
      const pending_result = {};
      memberRevenueStats.forEach((data) => {
        const { year, month } = data;
        const { amount, total_pending_amount } = data;
        if (!result[year]) {
          result[year] = Array(12).fill(0); // Initialize the array with zeros for all months
          pending_result[year] = Array(12).fill(0); // Initialize the array with zeros for all months
          setDetails(result);
          setPendingDetails(pending_result);
        }
        result[year][month - 1] = amount; // Update the amount for the corresponding month
        pending_result[year][month - 1] = total_pending_amount; // Update the amount for the corresponding month
        setDetails(result);
        setPendingDetails(pending_result);
      });
    } else {
      const result = {};
      const pending_result = {};
      result[moment().year()] = Array(12).fill(0); // Initialize the array with zeros for all months
      pending_result[moment().year()] = Array(12).fill(0); // Initialize the array with zeros for all months
      setDetails(result);
      setPendingDetails(pending_result);
    }
  }, [memberRevenueStats]);

  // const getCommulativeResults = (array) => {
  //     let new_array = [];
  //     array.map((item, index) => {
  //         console.log(item);
  //         new_array[index] = index > 0 ? new_array[index] + item  : item;
  //     });
  //     console.log(new_array, "new_Array");
  //     return new_array;
  // };

  const transformedResult = Object.entries(details).map(([year, data]) => ({
    name: year.toString(),
    data: data,
  }));
  const handleCSV = async () =>{
    try {
      const payload = {
        month:month,
        year:year
      }
      const baseUrl = process.env.REACT_APP_STAGE==='development'?'apidev.mosqapp.com':'api.mosqapp.com'
      const res = await fetch(`https://${baseUrl}/agent/export-functionality`,{
        method:'POST',
        body:JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      console.log(res.data);
      setShowModal(true)
    } catch (error) {
      console.log(error);
    }
   
  }
  
  const getMaxMonth = () => {
    const currentDate = moment();

    if (currentDate.date() === 1) {
      return currentDate.subtract(1, 'month').endOf('month');
    }
    return currentDate.endOf('month');
  };
  const disabledMonths = (current) => {
    const date = new Date(activeEntity.created_at);
    const minMonth=  moment(date).startOf('month'); 
   const maxMonth =  getMaxMonth()
   return current && (current < minMonth || current > maxMonth);
  };
  const disabledYears = (current) =>{
    const date = new Date(activeEntity.created_at);
    const minYear = moment(date).startOf('year');
    const maxYear = moment().endOf('year');
    return current && (current < minYear || current > maxYear);
  }
  const pendingTransformedResult = Object.entries(pending_details).map(
    ([year, data]) => ({
      name: year.toString(),
      data: data,
    })
  );
  console.log('month-->',month, year);
  const handleMonthChange = (date) => {
    if (date && !disabledMonths(date)) {
      setMonth(date.month() + 1);
    }
  };
  console.log(
    "details",
    details,
    pending_details,
    transformedResult,
    pendingTransformedResult
  );
  if (details && pending_details) {
    return (
      <Box mt="44px">
        <FinancialBoxShadow>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",

              paddingRight: "40px",
            }}
          >
            <Label
              fontSize="24px"
              style={{ width: "max-content", paddingLeft:"15px" }}
              fontWeight="700"
              justifyContent="flex-start"
            >
              {t("Totale inkomsten")}
            </Label>
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                width: "max-content",
                alignItems: "center",
              }}
            >
              <div className="col" style={{ width: "170px" }}>
                <label
                  htmlFor="Datum"
                  style={{
                    display: "block",
                    fontWeight: 500,
                    color: "#7E7E7E",
                    fontSize: "14px",
                  }}
                >
                  {t("Select Month")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <DatePicker.MonthPicker
                  className="input-box calendar"
                  placeholder={t("maand")}
                  style={{ height: "40px" }}
                  // value={month && moment().month(month)}
                  onChange={handleMonthChange}
                  format="MMMM"
                  disabledDate={disabledMonths}
                  suffixIcon={<Icon iconName="calendar"/>}
                />
                {/* {changedFields.Datum && formik.errors.Datum && (
                    <ErrorMessage>{t(formik.errors.Datum)}</ErrorMessage>
                  )} */}
              </div>
              <div className="col" style={{ width: "170px" }}>
                <label
                  htmlFor="Datum"
                  style={{
                    display: "block",
                    fontWeight: 500,
                    color: "#7E7E7E",
                    fontSize: "14px",
                  }}
                >
                  {t("Select Year")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <DatePicker
                  className="input-box calendar"
                  placeholder={t("jaar")}
                  style={{ height: "40px" }}
                  onChange={(e)=>setYear(e.$y)}
                  disabledDate={disabledYears}
                  picker="year"
                  suffixIcon={<Icon iconName="calendar"/>}
                />
                {/* {changedFields.Datum && formik.errors.Datum && (
                    <ErrorMessage>{t(formik.errors.Datum)}</ErrorMessage>
                  )} */}
              </div>
              <div
                style={{
                  cursor: "pointer",
                  color: "white",
                  background: " #D0004B",
                  borderRadius: "16px",
                  fontSize: "14px",
                  width: "90px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "28px",
                  gap:'5px',
                  marginTop: "20px",
                  pointerEvents: month && year ? "" : "none",
                }}
                onClick={handleCSV}
              >
                
                  <Icon iconName="downloadWhite" />
                  CSV
                
              </div>
            </div>
          </div>
          <LineChart
            dataSet={[
              transformedResult ? transformedResult[0] : [],
              pendingTransformedResult ? pendingTransformedResult[0] : [],
            ]}
            colors={["#80CBC4", "#D0004B"]}
            categories={[
              "Jan",
              "Feb",
              "Mrt",
              "Apr",
              "Mei",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Okt",
              "Nov",
              "Dec",
            ]}
            xLabel="Month"
            yLabel="Amount"
            legends ={['Amount recieved','Amount pending']}
          />
        </FinancialBoxShadow>
        <DefaultModal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px"}}
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div style={{paddingTop:'20px', paddingBottom:'20px'}}>
        <Label style={{justifyContent:'center'}}>{t('The CSV has been sent to your registered E-mail addres')} !!</Label>
        <div className="d-flex justify-content-around mt-3" >
                    <button className="fill-bt" style={{height:'46px'}} onClick={()=>setShowModal(false)}>
                    {t('Ja')}
                    </button>
        </div>
        </div>
        
      </DefaultModal>
      </Box>
    );
  }
  return null;
};

export default RevenueCard;
