import React, { useRef, useState, forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Congratulation from "./Congratulation";
import SelectMosqueBoxPlan from "./SelectMosqueBoxPlan";
import Label from "./../../../../../components/Label/Label";
import Icon from "./../../../../../components/Icon/index";
import Box from "./../../../../../components/Box/Box";
import ErrorMessage from "../../../../../components/ErrorMessage";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Canvas from "../../../../../components/Canvas/Canvas";
import { setOnboardingDetails } from "../../../customerAction";
import { alignItems, textAlign } from "styled-system";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  Bedrijfsn: Yup.string().required("Bedrijfsnaam: is vereist."),
  kvk: Yup.string().required("KVK nummer: is vereist."),
  Contactnaam: Yup.string().required("Contactnaam: is vereist."),
  emailadres: Yup.string()
    .email("invalid email")
    .required("Contact emailadres: is vereist."),
  NaamInitialen: Yup.string().required("Naam Initialen: is vereist."),
  Achternaam: Yup.string().required("Achternaam: is vereist."),
  Plaats: Yup.string().required("Plaats: is vereist."),
  Datum: Yup.string().required("Datum: is vereist."),
  checkbox: Yup.boolean().oneOf(
    [true],
    "MessageIk ga akkoord met de algemene voorwaarden van zowel, de transactieverwerker Buckaroo en Mosqapp. En de hierboven vermelde (optionele) transactiekosten.  moet Ja zijn."
  ),
});

const emStyle = {
  display: "block",
  marginBottom: "20px",
  color: "#7E7E7E",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 400,
  textAlign: "left",
};

const MosqueStepThree = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboardingDetails = useSelector(
    (state) => state.customerReducer.onboardingDetails
  );
  const [submitting, setSubmitting] = useState(false);
  const [isCanvasDrawn, setIsCanvasDrawn] = useState(null);
  const [canvasError, setCanvasError] = useState(false);
  const [changedFields, setChangedFields] = useState({
    Bedrijfsn: false,
    kvk: false,
    Contactnaam: false,
    emailadres: false,
    NaamInitialen: false,
    Achternaam: false,
    Plaats: false,
    Datum: false,
    checkbox: false,
  });
  const [signature, onSignatureDrawn] = useState(null);
  // const formRef = useRef(null);
  const { isAppliedTnC } = onboardingDetails;
  // const formRef = ref;
  const handleSubmit = async (values) => {
    console.log("handle sunit called");
    try {
      if (!isCanvasDrawn) {
        setCanvasError(true);
        return;
      }

      setSubmitting(true);
      // Perform form submission logic here
      console.log(values);
      // Set submitting to false after successful submission
      setSubmitting(false);
    } catch (error) {
      // Handle form submission error
      console.error(error);
      setSubmitting(false);
    }
  };
  // React.useImperativeHandle(ref, () => ({
  //   submit: () => {
  //     if (formRef.current) formRef.current.submit();
  //   }
  // }))
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const formik = useFormik({
    initialValues: {
      Bedrijfsn: "",
      kvk: "",
      // Contactnaam: "",
      // emailadres: "",
      NaamInitialen: "",
      Achternaam: "",
      Plaats: "",
      Datum: getCurrentDate() || "",
      checkbox: false,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const { values, errors } = formik;

  const is_onboarded = useSelector(
    (state) => state.customerReducer.is_onboarded
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setOnboardingDetails(name, value));
    formik.handleChange(e);
  };
  // React.useImperativeHandle(ref, () => ({
  //   submitForm: () => {
  //     if (formik) {
  //       formik.handleSubmit(); // Call handleSubmit from formik
  //     }
  //   },
  // }));
  useEffect(() => {
    if (
      values.Bedrijfsn !== "" &&
      values.kvk !== "" &&
      // values.Contactnaam !== "" &&
      // values.emailadres !== "" &&
      values.NaamInitialen !== "" &&
      values.Achternaam !== "" &&
      values.Plaats !== "" &&
      values.Datum !== "" &&
      isCanvasDrawn &&
      Object.keys(errors).length <= 2
    ) {
      props.setIsStepThreeErrors(false);
    } else props.setIsStepThreeErrors(true);
  }, [values, isCanvasDrawn, props, errors]);

  useEffect(() => {
    dispatch(setOnboardingDetails("signUrl", signature));
  }, [signature, dispatch]);

  return (
    <div style={{ margin: "30px 0px", overflow: "hidden" }}>
      {!is_onboarded ? (
        <>
          {/* <iframe  ref={iframeRef} src="https://www.cognitoforms.com/f/n1YCB8wHoUC9h1vFW8FUrQ/12" 
        style={{ border: "0", width: "100%", height: "1200px", overflow: "hidden"}}></iframe>
        <script src="https://www.cognitoforms.com/f/iframe.js"></script> */}
          <div
            className="form-container stepThreeForm"
            style={{ marginBottom: "20px" }}
          >
            {/* <div
              className="form-logo"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src="assets/Logo.png" height="70px" alt="mosqapp-logo"></img>
            </div>
            <hr></hr> */}
            {/* <h4
              style={{
                fontWeight: 600,
                fontSize: "22px",
                color: "#1A1A1A",
                margin: "30px 0px",
              }}
            >
              By Moodus
            </h4> */}
            <form
              // ref={formRef}

              method="POST"
              // onSubmit={(e) => {
              //   console.log('submit form');
              //   e.preventDefault(); // Prevent default form submission behavior
              //   formik.handleSubmit(e); // Manually call Formik's handleSubmit
              // }}
              style={{ display: "flex", flexDirection: "column", gap: 28 }}
            >
              <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="Bedrijfsn"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Bedrijfsnaam")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Bedrijfsn"
                    name="Bedrijfsn"
                    value={formik.values.Bedrijfsn}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Bedrijfsn: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Bedrijfsn && formik.errors.Bedrijfsn && (
                    <ErrorMessage>{t(formik.errors.Bedrijfsn)}</ErrorMessage>
                  )}
                </div>

                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="kvk"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    KVK nummer
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="kvk"
                    name="kvk"
                    value={formik.values.kvk}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, kvk: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.kvk && formik.errors.kvk && (
                    <ErrorMessage>{t(formik.errors.kvk)}</ErrorMessage>
                  )}
                </div>
              </div>
              {/* <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="Contactnaam"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    Contactnaam<span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Contactnaam"
                    name="Contactnaam"
                    value={formik.values.Contactnaam}
                    onChange={(e)=>{
                      handleChange(e);
                      setChangedFields({...changedFields,Contactnaam:true})
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Contactnaam && formik.errors.Contactnaam && (
                    <ErrorMessage>{formik.errors.Contactnaam}</ErrorMessage>
                  )}
                </div>

                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="emailadres"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    Contact emailadres
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="emailadres"
                    name="emailadres"
                    value={formik.values.emailadres}
                    onChange={(e)=>{
                    handleChange(e);
                      setChangedFields({...changedFields,emailadres:true})
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.emailadres && formik.errors.emailadres && (
                    <ErrorMessage>{formik.errors.emailadres}</ErrorMessage>
                  )}
                </div>
              </div> */}
              <div>
                <h6
                  style={{
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  MosqApp
                </h6>
                <p style={emStyle}>
                  {t(
                    "Wij bieden u onze ledenadministratie-tool kosteloos aan. U " +
                      "kunt dus zoveel gebruik maken van ons systeem als u wilt. Wij " +
                      "vragen alleen een vergoeding voor de transacties die " +
                      "daadwerkelijk plaatsvinden. Hiermee dekken wij o.a. de bank en " +
                      "betalingsverwerker kosten. En de kosten voor het onderhoud van " +
                      "de systemen en servers. Hiervoor vragen wij"
                  )}
                  &nbsp;
                  <strong style={{ color: "#1A1A1A", fontSize: "16px" }}>
                    €0,75&nbsp;(incl btw)
                  </strong>
                  &nbsp;per transactie.
                </p>
                <p style={emStyle}>
                  {t("Maakt u enkel gebruik van de ledenadministratie en geen")}
                  {t("gebruik van de betaalmogelijkheden?")}&nbsp;
                  <strong style={{ color: "#1A1A1A", fontSize: "16px" }}>
                    {t("Dan blijft MosqApp helemaal gratis!")}
                  </strong>
                </p>
                <a
                  href={`https://staging.mosqapp.com/terms-and-conditions`}
                  style={{ color: "#3200FF", textDecoration: "underline" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Algemene voorwaarden MosqApp")}
                </a>
              </div>
              <div>
                <h6
                  style={{
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  {t("Transactieverwerking")}
                </h6>
                <p style={emStyle}>
                  {t(
                    `Voor de transactieverwerking maakt Moodus gebruik van Buckaroo betaaldiensten. De Buckaroo betaaldienst bestaat uit`
                  )}
                  &nbsp;{" "}
                  {t(
                    `het beschikbaar stellen van de mogelijkheid om elektronisch betalingsverkeer te verrichten. De overeenkomst wordt`
                  )}
                  &nbsp;{" "}
                  {t(
                    `aangegaan onder de opschortende voorwaarde van acceptatie van de klant door Buckaroo B.V.. De Algemene Voorwaarden`
                  )}
                  &nbsp;{" "}
                  {t(
                    `Buckaroo zijn van toepassing op deze overeenkomst, waarmee u zich akkoord verklaart. U verklaart kennis te hebben`
                  )}
                  &nbsp;{" "}
                  {t(
                    `genomen van de Algemene Voorwaarden als onderdeel van de aanvraagprocedure en als`
                  )}
                  &nbsp; {t(`vermeld op de website`)}
                  &nbsp;
                  <Link
                    to="https://www.buckaroo.nl/algemene-voorwaarden"
                    rel="noreferrer"
                    target="_blank"
                    style={{ color: "#3200FF", textDecoration: "underline" }}
                  >
                    https://www.buckaroo.nl/algemene-voorwaarden
                  </Link>
                  &nbsp;{t("en deze te accepteren.")}
                </p>
              </div>
              <div className="checkbox">
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <input
                    type="checkbox"
                    id="form-check-box"
                    name="checkbox"
                    value={t(formik.values.checkbox)}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, checkbox: true });
                    }}
                    style={{
                      marginRight: "10px",
                      height: "24px",
                      width: "50px",
                      opacity: "60%",
                      border: "2px solid #D9DBE9",
                      borderRadius: "4px",
                    }}
                  ></input>
                  <label
                    htmlFor="form-check-box"
                    style={{ ...emStyle, display: "inline", color: "#1A1A1A" }}
                  >
                    {t(
  `Ik ga akkoord met de algemene voorwaarden van zowel, de ` +
  `transactieverwerker Buckaroo en Mosqapp. En de hierboven ` +
  `vermelde (optionele) transactiekosten.`
)}.
                  </label>
                </div>
                {changedFields.checkbox && formik.errors.checkbox && (
                  <ErrorMessage>{t(formik.errors.checkbox)}</ErrorMessage>
                )}
              </div>
              <div
                className="second-group row"
                // style={{ display: "flex", gap: "20px", flexWrap:'wrap' }}
              >
                <div className="col col-12 col-sm-12 col-lg-3 mb-sm-2">
                  <label
                    htmlFor="NaamInitialen"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Naam Initialen")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="NaamInitialen"
                    name="NaamInitialen"
                    value={formik.values.NaamInitialen}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({
                        ...changedFields,
                        NaamInitialen: true,
                      });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.NaamInitialen &&
                    formik.errors.NaamInitialen && (
                      <ErrorMessage>
                        {t(formik.errors.NaamInitialen)}
                      </ErrorMessage>
                    )}
                </div>
                <div className="col col-12 col-sm-12 col-lg-3 mb-sm-2">
                  <label
                    htmlFor="Achternaam"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Achternaam")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Achternaam"
                    name="Achternaam"
                    value={formik.values.Achternaam}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Achternaam: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Achternaam && formik.errors.Achternaam && (
                    <ErrorMessage>{t(formik.errors.Achternaam)}</ErrorMessage>
                  )}
                </div>
                <div className="col col-12 col-sm-12 col-lg-3 mb-sm-2">
                  <label
                    htmlFor="Plaats"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Plaats")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Plaats"
                    name="Plaats"
                    value={formik.values.Plaats}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Plaats: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Plaats && formik.errors.Plaats && (
                    <ErrorMessage>{t(formik.errors.Plaats)}</ErrorMessage>
                  )}
                </div>
                <div className="col col-12 col-sm-12 col-lg-3">
                  <label
                    htmlFor="Datum"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Datum")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Datum"
                    name="Datum"
                    value={formik.values.Datum}
                    disabled
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Datum: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box calendar"
                  />
                  {changedFields.Datum && formik.errors.Datum && (
                    <ErrorMessage>{t(formik.errors.Datum)}</ErrorMessage>
                  )}
                </div>
              </div>
              <div className="canvas" style={{ position: "relative" }}>
                <label
                  htmlFor="canvas"
                  style={{
                    display: "block",
                    fontWeight: 500,
                    color: "#7E7E7E",
                    fontSize: "16px",
                  }}
                >
                  {t("Handtekening")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Canvas
                  id="canvas"
                  setCanvasError={setCanvasError}
                  setIsCanvasDrawn={setIsCanvasDrawn}
                  onSignatureDrawn={onSignatureDrawn}
                />
              </div>
              {canvasError && (
                <ErrorMessage style={{ marginTop: "-20px" }}>
                  {t(" Handtekening: is vereist.")}
                </ErrorMessage>
              )}
            </form>
          </div>
          <Label variant="text2">{t("Uw abonnement omvat")}</Label>
          <Box my="25px">
            <Box display="flex" alignItems="center">
              <Icon iconName="fourSquare" mr="10px" />
              <Box>
                <Label variant="text5" fontWeight={500}>
                  {t("Ledenbeheer")}
                </Label>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" my="20px">
              <Icon iconName="fourSquare" mr="10px" />
              <Box>
                <Label variant="text5" fontWeight={500}>
                  {t("Betalingsbeheer")}
                </Label>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Icon iconName="fourSquare" mr="10px" />
              <Box>
                <Label variant="text5" fontWeight={500}>
                  {t("Herinneringen voor betalingen per e-mail")}
                </Label>
              </Box>
            </Box>
          </Box>
          {/*<Box className="checkbox-container mt-4" ml="0px">
          Door hier te klikken gaat u akkoord met de algemene voorwaarden en het privacybeleid
            {isAppliedTnC && <input type="checkbox" checked="checked" />}
            <span
              class="checkmark"
              onClick={() => handleChange("isAppliedTnC", !isAppliedTnC)}
            />
          </Box>*/}
          <Box>
            <a
              target="_blank"
              href="https://staging.mosqapp.com/terms-and-conditions"
            >
              {t('Algemene voorwaarden')}
            </a>
            <br />
            <a
              target="_blank"
              href="https://staging.mosqapp.com/privacy-policy"
            >
             {t('Privacybeleid')}
            </a>
          </Box>
        </>
      ) : (
        <Congratulation />
      )}
    </div>
  );
});

export default MosqueStepThree;
