import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "../../../components/Box";
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/Buttons";
import useOutsideClick from "../../../customHooks/useOutsideClick";
import DefaultModal from "../../../components/DefaultModal/DefaultModal";
import { useNavigate } from "react-router-dom";
import { getFormattedRelativeTime } from "../../../util/timeUtility";
import { fetchCities, memberApprove, setMemberDetailsVisible } from "../mosqueAction";
import { truncate } from "lodash";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { marginTop } from "styled-system";
import Label from "../../../components/Label";
import Input from "../../../components/Input";
import { baseUrl } from "../../../app.config";
import { MEMBER_APPROVAL_STATUS, UPDATE_APPROVAL_STATUS } from "../mosqueActionType";
import { showNotification } from "../../../util/Utility";
import ProfileModal from "../pages/Members/components/ProfileModal";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [showSupportDialog, setShowSupportDialog] = useState(false);
  const [supportItem, setSupportItem] = useState(null);
  const [supportApprovalId, setSupportApprovalId] = useState(null);
  const [supportUser, setSupportUser] = useState(null);
  const [showLatestUserPrompt, setShowLatestUserPrompt] = useState(false);
  const isMemberModalVisible = useSelector(
    (state) => state.mosqueReducer.isMemberModalVisible
  );
  const {t} = useTranslation()

  const user = useSelector((state) => state.mosqueReducer.agent);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const approvalList = useSelector((state) => state.mosqueReducer.approvalList);
  console.log('approvallist',approvalList);
  const approvalListCount = useSelector(
    (state) => state.mosqueReducer.approvalListCount
  );

  const setInitialValue = () => {
    setIsShown(false);
  };

  const ref = useOutsideClick(() => setInitialValue());

  const handleClickProfile = () => {
    setIsShown(true);
  };
 
  const handleApproval = async (memberSubId, approvalId, item) => {
    console.log('iten ->', item);
    if(item?.code === "support query"){
        setSupportApprovalId(item?._id);
         const res1 = await fetch(`${baseUrl}/v1/get-support?supportId=${item?.ref_id}`)
         const support = await res1.json();

      const baseUrl2 = process.env.REACT_APP_STAGE==='development'?'apidev.mosqapp.com':'api.mosqapp.com'
         const res2 = await fetch(`https://${baseUrl2}/onboarding/mosque/${item?.entity_id}/member/${support?.userId}`,{
          headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
        })
        const user = await res2.json();
        console.log('support item -->', support, user);
        setSupportUser(user);
       setShowSupportDialog(true);

       const { _id: userId, ...restUser } = user;
       const supportItem = { ...support, ...restUser, userId };
       setSupportItem(supportItem);
    }
    else{
    navigate(
      `/mosque/${activeEntity?._id}/members/add?memberSubId=${memberSubId}&approvalId=${approvalId}`,
      { state: item}
    );
  }
    setShow(false);
  };

  const handleClickLink = (link) => {
    setIsShown(false);
    navigate(link);
  };

  const handleSupportReview = async () =>{
    try {
      dispatch({
        type: MEMBER_APPROVAL_STATUS,
        approvalId:supportApprovalId,
      });
      const res1 = await fetch(`${baseUrl}/v1/approve-support?supportId=${supportItem._id}`)
      setShowSupportDialog(false);
    } catch (error) {
      showNotification("error", t("some error occured"));
    }
  }

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_type");
    window.location = "/login";
  };
 console.log( activeEntity);
 
 const showMemberDetails = (item = supportUser, val = true) => {
  // setShowSupportDialog(false);
  console.log(' showMemberDetails trigerred ...........................', val);
  
  dispatch(setMemberDetailsVisible(item, val));
  if (item?.country) {
    dispatch(fetchCities(item?.country));
  }
};

useEffect(()=>{
 const getLatestUser= async () =>{
    try {
      const storedUser = JSON.parse(localStorage.getItem('latest-user'));
      const latestUser = approvalList.find(user => user.code === "member-onboarding");
      if(!latestUser) return;
      if(storedUser){
        if(storedUser.created_at < latestUser.created_at)
        {
          localStorage.setItem('latest-user', JSON.stringify(latestUser));
          setShowLatestUserPrompt(true)
        }
      }else{
        localStorage.setItem('latest-user', JSON.stringify(latestUser));
        setShowLatestUserPrompt(true)
      }
    } catch (error) {
      console.error(error);
    }
 }
 
 if(approvalList.length>0)
 getLatestUser()
},[approvalList])

  return (
    <div ref={ref}>
      <div className="header_sec">
        <div className={open ? "header-flex" : "black-header-flex"}>
          <h1>Hallo {truncate(activeEntity?.name, { length: 20 })} 👋🏼</h1>
          <div className="d-flex align-items-center">
            <div>
              <div className="noti-msg" onClick={() => setShow(true)}>
                {!show && (
                  <span className="notification">{approvalListCount}</span>
                )}
                {!show ? <Icon iconName="BellIcon" /> : <Box width="21px" />}
              </div>
            </div>
            <div
              className="admin-profile-links"
              style={{ cursor: "pointer" }}
              onClick={() => handleClickProfile()}
            >
              <div className="profile-im">
                {user?.profile_pic ? (
                  <img
                    src={user?.profile_pic}
                    width="45px"
                    height="45px"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <Box
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                    bg="#D0004B"
                  >
                    <div className={"text-center"} style={{ paddingTop: "8px", color: "white" }}>{user && user.contact_name ? user?.contact_name[0] : ""}</div>
                  </Box>
                )}
              </div>
              <div style={{ position: "absolute", right: "0px" }}>
                <Icon
                  iconName="rightIcon"
                  style={
                    isShown
                      ? { cursor: "pointer", transform: "rotate(180deg)" }
                      : { cursor: "pointer" }
                  }
                  iconSize="tiny"
                />
              </div>
            </div>
            {isShown && (
              <ul className="admin-profile-links-ul">
                <li
                  onClick={() =>
                    handleClickLink(`/mosque/${activeEntity?._id}/profile`)
                  }
                >
                  {t('Mijn profiel')}
                </li>
                {/*<li><Link to={`/mosque/${activeEntity?._id}/my-plans`}>My Plan</Link></li>*/}
                {/*<li><Link to={`/mosque/${activeEntity?._id}/pay-now`}>Pay Now</Link></li>*/}
                {/*<li onClick={() => handleClickLink(`/mosque/${activeEntity?._id}/admins?new`)}>Add Admin</li>*/}
                {/*<li onClick={() => handleClickLink(`/mosque/${activeEntity?._id}/admins?exist`)}>Exiting Admin</li>*/}
                <li onClick={() => handleLogOut()}>{t('Uitloggen')}</li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="notification-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="noti-msg-white" onClick={() => setShow(true)}>
          <span className="notification">{approvalListCount}</span>
          <Icon iconName="BellIconWhite" />
        </div>
        <div className="noti-popup">
          <div className="d-flex justify-content-between noti-heading" >
            <h5>{t('Meldingen')}</h5>
            {/* <h6>Mark As Read</h6> */}
          </div>
          <div className="modal-scroll">
            {approvalList &&
              approvalList.map((item) => (
                <>
                  <div className="border-bottom mb-4 mt-3"></div>
                  <div className="msg-notification">
                    <span className="dot"></span>
                    <div className="bblue">{"#"}</div>
                    <div className="ml-4">
                      <p>
                        <b >{item?.task_name} </b>
                      </p>
                      <small>
                        {item?.created_at.substring(0,10)}
                      </small>
                      <p>
                        <Box my="10px">
                          <Box>
                            <Button
                              type="outlineColor"
                              text={t("Review")}
                              height="35px"
                              minHeight="35px"
                              onClick={() =>
                                handleApproval(item?.ref_id, item?._id,item)
                              }
                            />
                          </Box>
                        </Box>
                      </p>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </DefaultModal>
      <DefaultModal
        show={showSupportDialog}
        onHide={() => setShowSupportDialog(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px"}}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div
            style={{ cursor: "pointer", position:'absolute', right:'15px'}}
            title="Close"
            onClick={() =>setShowSupportDialog(false)}
          >
            <Icon style={{ zIndex: 2000}}  iconName="cross" />
          </div>
       <div style={{margin:'15px'}}>
         <h3>{t('Support Query')}</h3>
         <div>
          <div style={{marginBottom:'20px'}}>
          <Label>{t('Naam')}</Label>
          <input
                value={supportItem?.name}
                className="input-box"
                // onClick={() => showMemberDetails()}
                disabled
          />
          </div>
          <div style={{marginBottom:'20px'}}>
          <Label>{t('Subject')}</Label>
          <input
                value={supportItem?.subject}
                className="input-box"
                disabled
          />
          </div>
          <div style={{marginBottom:'20px'}}>
          <Label>{t('Content')}</Label>
          <textarea
                value={supportItem?.content}
                className="input-boxx"
                //font-size: 18px;font-weight: 500; color: #1a1a1a;
                style={{ fontSize:'18px', fontWeight:500, color:'#1a1a1a',padding:'10px'}}
                disabled
          />
          </div>
         </div>
          <div style={{display:'flex', justifyContent:'center'}}>
          <Button
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                onClick={handleSupportReview}
              >
                {t('Review')}
          </Button>
          </div>
       </div>

      </DefaultModal>
      <DefaultModal
          show={isMemberModalVisible}
          onHide={() => showMemberDetails(null, false)}
          className="profile-modal"
          style={{ paddingRight: "0px", background: "blue" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
         <ProfileModal
          onHide={showMemberDetails} 
          />
        </DefaultModal>
        <DefaultModal
          show={showLatestUserPrompt}
          onHide={() => setShowLatestUserPrompt(false)}
          className="congrats-modal"
          style={{ paddingRight: "0px", background: "blue" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
         <div>
          <p style={{textAlign:'center'}}>{t('Hey, a new user registered please approve by clicking the notification Icon.')}</p>
          <div style={{display:'flex',justifyContent:'center'}}>
          <Button
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                onClick={()=>setShowLatestUserPrompt(false)}
              >
                {t('Ok')}
          </Button>
          </div>
         </div>
        </DefaultModal>
    </div>
  );
}

export default Header;
